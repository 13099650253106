import { create } from "zustand";
import { getServerMgr } from "../managers/ServerMgr";

export const useMapitoStore = create((set, get) => ({
    //MODAL
    //CompleteModal
    showCompleteModal: false,
    setShowCompleteModal: (show, info) => {
        set({            
            showCompleteModal: show,
            completeModalInfo: info
        })
    },
    completeModalInfo: {},

    //AddAppoModal
    showAddAppoModal: false,
    setShowAddAppoModal: (show, info) => {
        set({            
            showAddAppoModal: show,
            addAppoModalInfo: info
        })
    },
    addAppoModalInfo: {},
    
    //DeleteAppoModal
    showDeleteAppoModal: false,
    setShowDeleteAppoModal: (show, info) => {
        set({            
            showDeleteAppoModal: show,
            deleteAppoModalInfo: info
        })
    },
    deleteAppoModalInfo: {},
    
    //DeleteMarkerModal
    showDeleteMarkerModal: false,
    setShowDeleteMarkerModal: (show, info) => {
        set({            
            showDeleteMarkerModal: show,
            deleteMarkerModalInfo: info
        })
    },
    deleteMarkerModalInfo: {},
    
    //EditAppoModal
    showEditAppoModal: false,
    setShowEditAppoModal: (show, info) => {
        set({            
            showEditAppoModal: show,
            editAppoModalInfo: info
        })
    },
    editAppoModalInfo: {},
    
    //EditMarkerModal
    showEditMarkerModal: false,
    setShowEditMarkerModal: (show, info) => {
        set({            
            showEditMarkerModal: show,
            editMarkerModalInfo: info
        })
    },
    editMarkerModalInfo: {},

    //-------
    showModal: false,
    modalInfo: {type: "none", args: {}},
    setShowModal: (show, type, args) => {
        set({
            showModal: show,
            modalInfo:{
                type: type,
                args: args
            }
        })
    },
    setModal: (type, props) => {
        set({showModal: {type: type, props: props}})
    },
    closeModal: () => {set({showModal: {type: "none", props: {}}})},

    //DB DATA
    markersList: [],
    appointmentsList: [],
    appointmentTypes: [],
    updateData: async (cb) => {
        let appointmentsResults = await getServerMgr().getFullData()
        appointmentsResults = Array.isArray(appointmentsResults) ? appointmentsResults : []
        let appointmentsListClean = removeEmptyAppointments(appointmentsResults)
        let appointmentsListCleanParsed = parseAppoCompletion(appointmentsListClean)
        set({ appointmentsList: appointmentsListCleanParsed})
        let markersParsed = parseResult(appointmentsResults);
        set({markersList: markersParsed})
        let appointmentTypesResults = await getServerMgr().getAppointmentTypes()
        set ({appointmentTypes: appointmentTypesResults})
        if(cb) {
            cb()
        }
    },

    //SORTING
    sortingBy: "",
    setSortingBy: (value) => set({sortingBy: value}),

    sortingTypeList: [
        {value: "desc", label: "Decrescente"},
        {value: "asc", label: "Crescente"},
    ],

    markerSortBy: "Indirizzo",
    setMarkerSorter: (value) => {
        set({markerSortBy: value.target.value})
    },
    markerSortingType: "asc",
    setMarkerSortingType: (value) => {
        set({markerSortingType: value.target.value})
    },
    markerSortingList: [
        {value: "Completamento", label: "Completamento"},
        {value: "Indirizzo", label: "Indirizzo"},
        {value: "Interventi totali", label: "Interventi totali"},
        {value: "Interventi mancanti", label: "Interventi mancanti"},
        {value: "Amministratore", label: "Amministratore"},
    ],
    sortMarker: (a, b) => {
        let result = 0
        switch(get().markerSortBy) {
            case "Completamento":
                result = getMarkerColor(a.appointments) > getMarkerColor(b.appointments) ? 1 : -1;
                break;
            case "Indirizzo":
                result = a.address.toLowerCase() > b.address.toLowerCase() ? 1 : -1;
                break;
            case "Interventi totali":
                result = a.appointments.length > b.appointments.length ? 1 : -1;
                break;
            case "Interventi mancanti":
                result = calculateNotCompletedAppointments(a.appointments) > calculateNotCompletedAppointments(b.appointments) ? 1 : -1;
                break;
            case "Amministratore":
                result = (a.administrator.surname + a.administrator.name).toLowerCase() > (b.administrator.surname + b.administrator.name).toLowerCase() ? 1 : -1;
                break;
            default:
                return 0
        }
        return (get().markerSortingType === "asc") ? result : (result * -1)
    },
    
    appoSortBy: "Data intervento",
    setAppoSorter: (value) => {
        set({appoSortBy: value.target.value})
    },
    appoSortingType: "asc",
    setAppoSortingType: (value) => {
        set({appoSortingType: value.target.value})
    },
    appoSortingList: [
        {value: "Completato", label: "Completato"},
        {value: "Marker", label: "Marker"},
        {value: "Tipo intervento", label: "Tipo intervento"},
        {value: "Data intervento", label: "Data intervento"},
        {value: "Data completamento", label: "Data completamento"},
    ],
    sortAppo: (a, b) => {
        let result = 0
        switch(get().appoSortBy) {
            case "Completato":
                result = a.completed > b.completed ? 1 : -1;
                break;
            case "Marker":
                result = a.address.toLowerCase() > b.address.toLowerCase() ? 1 : -1;
                break;
            case "Tipo intervento":
                result = a.type_name.toLowerCase() > b.type_name.toLowerCase() ? 1 : -1;
                break;
            case "Data intervento":
                result = a.timestamp > b.timestamp ? 1 : -1;
                break;
            case "Data completamento":
                result = new Date(a.completion_date) > new Date(b.completion_date) ? 1 : -1;
                break;
            default:
                return 0
        }
        return (get().appoSortingType === "asc") ? result : (result * -1)
    },

    //FILTERS  
    numAppo: [{id: 0, value: "0"}, {id: 1, value: "1"}, {id: 2, value: "2"}, {id: 3, value: "3"}, {id: 4, value: "4"}, {id: 5, value: "5+"}],
    numAppoCompleted: [{id: 0, value: "1"}, {id: 1, value: "2"}, {id: 2, value: "3"}, {id: 3, value: "4+"}],
    workingState: [{id: 0, value: "Completato"}, {id: 1, value: "In corso"}, {id: 2, value: "Da iniziare"}],
    needWarningList: [{id: 0, value: "Senza avviso"}, {id: 1, value: "Con avviso"}],
    markerFilters: {
        address: "",
        administrator: "",
        numAppo: [],
        numAppoCompleted: [],
        needWarning: [],
        types: [],
        workingState: [],
        dateFrom: "",
        dateTo: ""
    },
    setMarkerFiltersAddress: (value) => set((state) => ({markerFilters: {...state.markerFilters, address: value}})),
    setMarkerFiltersAdministrator: (value) => set((state) => ({markerFilters: {...state.markerFilters, administrator: value}})),
    setMarkerFiltersNumAppo: (type, isRemove) => set((state) => {
        if(isRemove){
            var index = state.markerFilters.numAppo.indexOf(type);
            // if (index !== -1) {
            //   array.splice(index, 1);
            // }
            let newValue = {...state.markerFilters, numAppo: [...state.markerFilters.numAppo.slice(0, index), ...state.markerFilters.numAppo.slice(index + 1)]}
            return {markerFilters: newValue}
        } else {
            let newValue = {...state.markerFilters, numAppo: [...state.markerFilters.numAppo, type]}
            return {markerFilters: newValue}
        }
    }),
    setMarkerFiltersNumAppoCompleted: (type, isRemove) => set((state) => {
        if(isRemove){
            var index = state.markerFilters.numAppoCompleted.indexOf(type);
            // if (index !== -1) {
            //   array.splice(index, 1);
            // }
            let newValue = {...state.markerFilters, numAppoCompleted: [...state.markerFilters.numAppoCompleted.slice(0, index), ...state.markerFilters.numAppoCompleted.slice(index + 1)]}
            return {markerFilters: newValue}
        } else {
            let newValue = {...state.markerFilters, numAppoCompleted: [...state.markerFilters.numAppoCompleted, type]}
            return {markerFilters: newValue}
        }
    }),
    setMarkerFiltersNeedWarning: (type, isRemove) => set((state) => {
        if(isRemove){
            var index = state.markerFilters.needWarning.indexOf(type);
            // if (index !== -1) {
            //   array.splice(index, 1);
            // }
            let newValue = {...state.markerFilters, needWarning: [...state.markerFilters.needWarning.slice(0, index), ...state.markerFilters.needWarning.slice(index + 1)]}
            return {markerFilters: newValue}
        } else {
            let newValue = {...state.markerFilters, needWarning: [...state.markerFilters.needWarning, type]}
            return {markerFilters: newValue}
        }
    }),
    setMarkerFiltersType: (type, isRemove) => set((state) => {
        if(isRemove){
            var index = state.markerFilters.types.indexOf(type);
            // if (index !== -1) {
            //   array.splice(index, 1);
            // }
            let newValue = {...state.markerFilters, types: [...state.markerFilters.types.slice(0, index), ...state.markerFilters.types.slice(index + 1)]}
            return {markerFilters: newValue}
        } else {
            let newValue = {...state.markerFilters, types: [...state.markerFilters.types, type]}
            return {markerFilters: newValue}
        }
    }),
    setMarkerFiltersWorkingState: (type, isRemove) => set((state) => {
        if(isRemove){
            var index = state.markerFilters.workingState.indexOf(type);
            // if (index !== -1) {
            //   array.splice(index, 1);
            // }
            let newValue = {...state.markerFilters, workingState: [...state.markerFilters.workingState.slice(0, index), ...state.markerFilters.workingState.slice(index + 1)]}
            return {markerFilters: newValue}
        } else {
            let newValue = {...state.markerFilters, workingState: [...state.markerFilters.workingState, type]}
            return {markerFilters: newValue}
        }
    }),
    setMarkerFiltersDateFrom: (value) => {
        set((state) => ({markerFilters: {...state.markerFilters, dateFrom: value}}))
    },
    setMarkerFiltersDateTo: (value) => {
        set((state) => ({markerFilters: {...state.markerFilters, dateTo: value}}))
    },
    resetFilters: () => {
        set({markerFilters: {
            address: "",
            administrator: "",
            numAppo: [],
            numAppoCompleted: [],
            needWarning: [],
            types: [],
            workingState: [],
            dateFrom: "",
            dateTo: ""
        }})
    },
    filterMarker: (marker, filters) => {
        // let filters = get().markerFilters;

        if(!(filters.address == "" || marker.address.toLowerCase().includes(filters.address.toLowerCase()))){
            return false
        }

        if(!(filters.administrator == "" || (marker.administrator.surname.toLowerCase()+" "+marker.administrator.name.toLowerCase()).includes(filters.administrator.toLowerCase()) /*|| marker.administrator.name.toLowerCase().includes(filters.administrator.toLowerCase())*/)){
            return false
        }

        if(!(filters.numAppo.length == 0 || filters.numAppo.includes(marker.appointments.length) || (filters.numAppo.includes(5) && marker.appointments.length >= 5))){
            return false
        }

        let markerNeedWarning = marker.needWarning ? 1 : 0
        if(!(filters.needWarning.length == 0 || filters.needWarning.includes(markerNeedWarning))){
            return false
        }

        let markerTypes = marker.appointments.map((item) => (Number(item.type_id)))
        let typeFound = false;
        markerTypes.forEach((item) => {
            if(filters.types.includes(item)) {
                typeFound = true
            }
        })
        if(!(filters.types.length == 0 || typeFound)){
            return false
        }

        let isAppoCompleted = false;
        let isAppoNotCompleted = false;
        marker.appointments.forEach((item) => {item.completed ? isAppoCompleted = true : isAppoNotCompleted = true})
        let appoCompletedResult = (isAppoCompleted && isAppoNotCompleted) ? 1 : (isAppoCompleted || marker.appointments.length == 0 ? 0 : 2)
        if(!(filters.workingState.length == 0 || filters.workingState.includes(appoCompletedResult))){
            return false
        }

        let numAppoCompletedResult = 0;
        // let isAppoNotCompleted = false;
        marker.appointments.forEach((item) => {if(item.completed){
            numAppoCompletedResult++;
        }});
        let resultAppoCompleted;
        switch(numAppoCompletedResult){
            case 1:
                resultAppoCompleted = 0;
                break;
            case 2:
                resultAppoCompleted = 1;
                break;
            case 3:
                resultAppoCompleted = 2;
                break;
            case 4:
                resultAppoCompleted = 3;
                break;
            default:
                resultAppoCompleted = -1;
                break;
        };
        if(!(filters.numAppoCompleted.length == 0 || filters.numAppoCompleted.includes(resultAppoCompleted))){
            return false
        }

        if(filters.dateFrom != "" || filters.dateTo != ""){    
            if(!marker.appointments) {
                return false
            }
            let appoFound = false  
            for(let i = 0; i < marker.appointments.length && !appoFound; i++)  {   
                let appo = marker.appointments[i] 
                if(appo.date == "" || appo.date == "0000-00-00"){
                    continue;
                }
                let appoDate = new Date(appo.date)
                if(filters.dateFrom != "") {
                    let dateFrom = new Date(filters.dateFrom)
                    if(appoDate < dateFrom) {
                        continue;
                    }
                }
                if(filters.dateTo != "") {
                    let dateTo = new Date(filters.dateTo)
                    if(appoDate > dateTo) {
                        continue;
                    }
                }
                appoFound = true
            }
            if(!appoFound) {
                return false
            }
        }
        

        return true;
    },

    //APPO FILTERS
    workingStateAppo: [{id: 0, value: "Da eseguire"}, {id: 1, value: "Completato"}],
    appoFilters: {
        address: "",
        types: [],
        workingState: [],
        dateFrom: "",
        dateTo: "",
        completedFrom: "",
        completedTo: ""
    },
    setAppoFiltersAddress: (value) => set((state) => ({appoFilters: {...state.appoFilters, address: value}})),
    setAppoFiltersType: (type, isRemove) => set((state) => {
        if(isRemove){
            var index = state.appoFilters.types.indexOf(type);
            // if (index !== -1) {
            //   array.splice(index, 1);
            // }
            let newValue = {...state.appoFilters, types: [...state.appoFilters.types.slice(0, index), ...state.appoFilters.types.slice(index + 1)]}
            return {appoFilters: newValue}
        } else {
            let newValue = {...state.appoFilters, types: [...state.appoFilters.types, type]}
            return {appoFilters: newValue}
        }
    }),
    setAppoFiltersWorkingState: (type, isRemove) => set((state) => {
        if(isRemove){
            var index = state.appoFilters.workingState.indexOf(type);
            // if (index !== -1) {
            //   array.splice(index, 1);
            // }
            let newValue = {...state.appoFilters, workingState: [...state.appoFilters.workingState.slice(0, index), ...state.appoFilters.workingState.slice(index + 1)]}
            return {appoFilters: newValue}
        } else {
            let newValue = {...state.appoFilters, workingState: [...state.appoFilters.workingState, type]}
            return {appoFilters: newValue}
        }
    }),
    setAppoFiltersDateFrom: (value) => {
        set((state) => ({appoFilters: {...state.appoFilters, dateFrom: value}}))
    },
    setAppoFiltersDateTo: (value) => {
        set((state) => ({appoFilters: {...state.appoFilters, dateTo: value}}))
    },
    setAppoFiltersCompletedFrom: (value) => {
        set((state) => ({appoFilters: {...state.appoFilters, completedFrom: value}}))
    },
    setAppoFiltersCompletedTo: (value) => {
        set((state) => ({appoFilters: {...state.appoFilters, completedTo: value}}))
    },
    resetAppoFilters: () => {
        set({appoFilters: {
            address: "",
            types: [],
            workingState: [],
            dateFrom: "",
            dateTo: "",
            completedFrom: "",
            completedTo: ""
        }})
    },
    filterAppo: (appo, filters) => {
        // let filters = get().appoFilters;

        if(!(filters.address == "" || appo.address.toLowerCase().includes(filters.address.toLowerCase()))){
            return false
        }

        if(filters.types.length != 0){
            if(!filters.types.includes(Number(appo.type_id))) {
                return false
            }
        }

        if(filters.workingState.length != 0){
            if(!filters.workingState.includes(Number(appo.completed))){
                return false
            }
        }

        if(filters.dateFrom != "" || filters.dateTo != ""){            
            if(appo.date == "" || appo.date == "0000-00-00"){
                return false
            }
            let appoDate = new Date(appo.date)
            if(filters.dateFrom != "") {
                let dateFrom = new Date(filters.dateFrom)
                if(appoDate < dateFrom) {
                    return false
                }
            }
            if(filters.dateTo != "") {
                let dateTo = new Date(filters.dateTo)
                if(appoDate > dateTo) {
                    return false
                }
            }
        }

        if(filters.completedFrom != "" || filters.completedTo != ""){            
            if(!appo.completed) { //{if(appo.completed == 0){
                return false
            }
            let appoDate = new Date(appo.completion_date)
            if(filters.completedFrom != "") {
                let completedFrom = new Date(filters.completedFrom)
                if(appoDate < completedFrom) {
                    return false
                }
            }
            if(filters.completedTo != "") {
                let completedTo = new Date(filters.completedTo)
                if(appoDate > completedTo) {
                    return false
                }
            }
        }
        return true;
    },

    //MAP
    mapZoom: 14,
    setMapZoom: (newZoom) => set({mapZoom: newZoom}),
    mapCenter: {lat: 41.11565092518093, lng: 16.86904707978427},
    setMapCenter: (newCenter) => set({mapCenter: newCenter}),
}))

const removeEmptyAppointments = (appoArray) => {
    return appoArray.filter((item) => (item.appointment_id ? true : false))
}

const parseAppoCompletion = (appoArray) => {
    return appoArray.map((appo) => ({...appo, completed: appo.completed === '1' ? true : false}))
}

const parseResult = (resultsArray) => {
    let markersList = {}
    resultsArray.forEach((item) => {
      let currentMarker = (({id, lat, lng, address, marker_notes, needWarning, admin_id, surname, name, telephone, email}) => ({id, lat, lng, address, marker_notes, needWarning: needWarning == "1" ? true : false, appointments: [], administrator: {admin_id, surname, name, telephone, email}}))(item);
      markersList[item.id] ??= currentMarker
      if(item.appointment_id !== null) {
        let currentAppointment = (({appointment_id, date, time, type_id, type_name, completed, completion_date, appointment_notes}) => ({appointment_id, date, time, type_id, type_name, completed: completed === '1' ? true : false, completion_date, appointment_notes}))(item);
        markersList[item.id].appointments.push(currentAppointment)
      }
    })
    return markersList
}

const calculateNotCompletedAppointments = (appointmentsList) => {
    let notCompletedCount = 0;
    appointmentsList.forEach((item) => {
        if(!item.completed) {notCompletedCount++}
    });
    return notCompletedCount;
  } 

  const getMarkerColor = (appointmentsList) => {
    let completedFlag = false;
    let notCompletedFlag = false;
    for(let i = 0; i < appointmentsList.length /*|| !(completedFlag && notCompletedFlag)*/; i++){
        completedFlag ||= appointmentsList[i].completed;
        notCompletedFlag ||= !appointmentsList[i].completed;
    }
    if(completedFlag && notCompletedFlag){
        return 1
    }
    if(notCompletedFlag){
        return 2
    }
    return 0
  }